export const resourcesWithTags = [
  {
    title: "Millions of Accounts Vulnerable due to Google's OAuth Flaw",
    url: "https://web.archive.org/web/20250214231149/https://trufflesecurity.com/blog/millions-at-risk-due-to-google-s-oauth-flaw/",
    reference: "Millions of Americans can have their data stolen right now because of a deficiency in Google’s “Sign in with Google” authentication flow. If you’ve worked for a startup in the past - especially one that has since shut down - you might be vulnerable.",
    tags: ["dev", "security", "identity"],
    dateAdded: "28-02-2025",
  },
  {
    title: "Build It Yourself",
    url: "https://web.archive.org/web/20250209131536/https://lucumr.pocoo.org/2025/1/24/build-it-yourself/",
    reference: "You're probably familiar with the concept of 'dependency churn.' It's that never-ending treadmill of updates, patches, audits, and transitive dependencies that we as developers love to casually install in the name of productivity.",
    tags: ["dev"],
    dateAdded: "28-02-2025",
  },
  {
    title: "Hell Is Overconfident Developers Writing Encryption Code",
    url: "https://web.archive.org/web/20250219162025/https://soatok.blog/2025/01/31/hell-is-overconfident-developers-writing-encryption-code/",
    reference: "Overconfident developers that choose to write their own cryptography code have plagued the information security industry since before it was even an industry.",
    tags: ["dev", "security", "cryptography"],
    dateAdded: "28-02-2025",
  },
  {
    title: "httptap",
    url: "https://github.com/monasticacademy/httptap",
    reference: "View the HTTP and HTTPS requests made by any linux program.",
    tags: ["dev", "tool", "network"],
    dateAdded: "28-02-2025",
  },
  {
    title: "Developer philosophy",
    url: "https://web.archive.org/web/20250220224501/https://qntm.org/devphilo",
    reference: "A few weeks ago at work we had a talk where senior developers (including me) were invited to spend around five minutes each talking about our personal software development philosophies. The idea was for us to share our years of experience with our more junior developers.",
    tags: ["dev", "education"],
    dateAdded: "28-02-2025",
  },
  {
    title: "How Core Git Developers Configure Git",
    url: "https://web.archive.org/web/20250228151635/https://blog.gitbutler.com/how-git-core-devs-configure-git/",
    reference: "What git config settings should be defaults by now? Here are some settings that even the core developers change.",
    tags: ["dev", "git"],
    dateAdded: "28-02-2025",
  },
  {
    title: "Ask for no, don't ask for yes",
    url: "https://web.archive.org/web/20250227050237/https://www.mooreds.com/wordpress/archives/3518",
    reference: "One habit I like is to ask for no, not yes.",
    tags: ["entrepreneurship"],
    dateAdded: "28-02-2025",
  },
  {
    title: "Subtrace: Wireshark for Docker containers",
    url: "https://github.com/subtrace/subtrace",
    reference: "Subtrace is Wireshark for your Docker containers. It lets developers see all incoming and outgoing requests in their backend server so that they can resolve production issues faster.",
    tags: ["dev", "docker", "network"],
    dateAdded: "28-02-2025",
  },
  {
    title: "A year of uv: pros, cons, and should you migrate",
    url: "https://web.archive.org/web/20250227114054/https://www.bitecode.dev/p/a-year-of-uv-pros-cons-and-should",
    reference: "After one year of trying uv, the new Python project management tool by Astral, with many clients, I have seen what it's good and bad for. My conclusion is: if your situation allows it, always try uv first. Then fall back on something else if that doesn't work out.",
    tags: ["dev", "python"],
    dateAdded: "28-02-2025",
  },
  {
    title: "A calculator app? Anyone could make that.",
    url: "https://chadnauseam.com/coding/random/calculator-app/",
    reference: "A calculator should show you the result of the mathematical expression you entered. That's much, much harder than it sounds. What I'm about to tell you is the greatest calculator app development story ever told.",
    tags: ["computer science", "dev", "education"],
    dateAdded: "28-02-2025",
  },
  {
    title: "Nping",
    url: "https://github.com/hanshuaikang/Nping/",
    reference: "Nping is a Ping tool developed in Rust. It supports concurrent Ping for multiple addresses, visual chart display, real-time data updates, and other features.",
    tags: ["tool", "network"],
    dateAdded: "28-02-2025",
  },
  {
    title: "MapTCHA, the open source CAPTCHA that improves OpenStreetMap",
    url: "https://fosdem.org/2025/schedule/event/fosdem-2025-5879-maptcha-the-open-source-captcha-that-improves-openstreetmap/",
    reference: "OpenStreetMap (OSM) has many objects remaining to be mapped, but the quality of AI-generated objects is not high enough for direct inclusion. We introduce MapTCHA, a CAPTCHA that leverages the uncertainty of interpreting imagery with computer vision, and provides human verification for AI predictions: users are asked to identify images containing correctly interpreted objects, e.g. building outlines.",
    tags: ["dev", "captcha"],
    dateAdded: "28-02-2025",
  },
  {
    title: "The First Six Books of The Elements of Euclid With Coloured Diagrams and Symbols",
    url: "https://www.c82.net/euclid/",
    reference: "A reproduction of Oliver Byrne's celebrated work from 1847 plus interactive diagrams, cross references, and posters designed by Nicholas Rougeux.",
    tags: ["mathematics", "education"],
    dateAdded: "28-02-2025",
  },
  {
    title: "I am (not) a Failure: Lessons Learned From Six (and a half) Failed Startup Attempts",
    url: "https://web.archive.org/web/20250218140111/https://blog.rongarret.info/2025/01/i-am-not-failure-lessons-learned-from.html/",
    reference: "If my younger self had heard these stories he might have had a less stressful life knowing that even a long string of failures in specific endeavors doesn't necessarily translate to failure in life.  Maybe some version of my younger self is still out there and will get some similar value out of it.",
    tags: ["entrepreneurship"],
    dateAdded: "28-02-2025",
  },
  {
    title: "On the nature of Computing Science",
    url: "https://web.archive.org/save/https://www.cs.utexas.edu/~EWD/transcriptions/EWD08xx/EWD896.html",
    reference: "We all know, how computing is now expected to cure all ills of the world and more, and how as far as these expectations are concerned, even the sky is no longer accepted as the limit.",
    tags: ["computer science", "education"],
    dateAdded: "14-01-2025",
  },
  {
    title: "Operating System in 1,000 Lines",
    url: "https://operating-system-in-1000-lines.vercel.app/en/",
    reference: "We're going to build a small operating system from scratch, step by step.",
    tags: ["computer science", "education"],
    dateAdded: "14-01-2025",
  },
  {
    title: "Semantic Reader: An AI-Powered Augmented Scientific Reading Application",
    url: "https://www.semanticscholar.org/product/semantic-reader/",
    reference: "Semantic Reader is an augmented reader with the potential to revolutionize scientific reading by making it more accessible and richly contextual.",
    tags: ["computer science", "education", "research"],
    dateAdded: "14-01-2025",
  },
  {
    title: "mCaptcha",
    url: "https://mcaptcha.org/",
    reference: "mCaptcha is proof-of-work based captcha system that is privacy focused and fully automated.",
    tags: ["Dev", "captcha"],
    dateAdded: "14-01-2025",
  },
  {
    title: "Nearly All Binary Searches and Mergesorts are Broken",
    url: "https://research.google/blog/extra-extra-read-all-about-it-nearly-all-binary-searches-and-mergesorts-are-broken/",
    reference: "And now we know the binary search is bug-free, right? Well, we strongly suspect so, but we don't know. It is not sufficient merely to prove a program correct; you have to test it too. Moreover, to be really certain that a program is correct, you have to test it for all possible input values, but this is seldom feasible.",
    tags: ["Education", "Computer Science", "Dev"],
    dateAdded: "14-01-2025",
  },
  {
    title: "Falsehoods Programmers Believe About Names",
    url: "https://web.archive.org/web/20250102003939/https://www.kalzumeus.com/2010/06/17/falsehoods-programmers-believe-about-names/",
    reference: "I have never seen a computer system which handles names properly and doubt one exists, anywhere.",
    tags: ["Computer science", "Digital Identity", "Dev"],
    dateAdded: "04-01-2025",
  },
  {
    title: "Falsehoods programmers believe about time",
    url: "https://web.archive.org/web/20250102173731/https://infiniteundo.com/post/25326999628/falsehoods-programmers-believe-about-time",
    reference: "That thing about a minute being longer than an hour was a joke, right? No.",
    tags: ["Computer science", "time", "Dev"],
    dateAdded: "04-01-2025",
  },
  {
    title: "Seconds Since the Epoch",
    url: "https://web.archive.org/web/20250101185009/https://aphyr.com/posts/378-seconds-since-the-epoch",
    reference: "Consequently, every few years POSIX time jumps backwards, wreaking utter havoc. Someday it might jump forward.",
    tags: ["Computer science", "time", "Dev"],
    dateAdded: "04-01-2025",
  },
  {
    title: "Salary Negotiation: Make More Money, Be More Valued",
    url: "https://web.archive.org/web/20250102183302/https://www.kalzumeus.com/2012/01/23/salary-negotiation/",
    reference: "Your salary negotiation — which routinely takes less than 5 minutes to conclude — has an outsized influence on what your compensation is.",
    tags: ["Money"],
    dateAdded: "05-01-2025",
  },
  {
    title: "Developer Roadmaps",
    url: "https://roadmap.sh/",
    reference: "roadmap.sh is a community effort to create roadmaps, guides and other educational content to help guide developers in picking up a path and guide their learnings.",
    tags: ["Education", "Dev"],
    dateAdded: "05-01-2025",
  },
  {
    title: "Writing scientific articles like a native English speaker: top ten tips for Portuguese speakers",
    url: "https://www.scielo.br/j/clin/a/zcs47Q4bsW6yk7D86XQCVss/",
    reference: "I decided to assemble a compilation of the 10 most common errors made by native Portuguese speakers when writing scientific papers in English.",
    tags: ["Education", "English", "research"],
    dateAdded: "05-01-2025",
  }
];
  