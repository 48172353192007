// ResourceTagCheckboxes.jsx
import React from "react";

export default function ResourceTagCheckboxes({
  allTags,
  selectedTags,
  activeTags,
  onTagChange,
}) {
  return (
    <div className="mb-3 d-flex flex-wrap justify-content-center">
      {allTags.map((tag) => {
        const isSelected = selectedTags.includes(tag);
        // If the tag isn’t selected but isn’t present in any filtered resource, disable it.
        const isAvailable = activeTags.includes(tag);
        const disabled = !isSelected && !isAvailable;

        return (
          <div key={tag} className="form-check me-3 mb-2">
            <input
              className="form-check-input"
              type="checkbox"
              id={tag}
              value={tag}
              checked={isSelected}
              disabled={disabled}
              onChange={() => onTagChange(tag)}
            />
            <label
              className={`form-check-label ${
                tag === "NEW" ? "text-success fw-bold" : ""
              } ${disabled ? "text-muted" : ""}`}
              htmlFor={tag}
              style={disabled ? { cursor: "not-allowed" } : { cursor: "pointer" }}
            >
              {tag}
            </label>
          </div>
        );
      })}
    </div>
  );
}
