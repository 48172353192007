// Resources.jsx
import React, { useState } from "react";
import { MDBCardTitle } from "mdb-react-ui-kit";
import { useResources } from "./useResources";
import ResourceTagCheckboxes from "./ResourceTagCheckboxes";
import ResourceCard from "./ResourceCard";

export default function Resources() {
  // Searching & tag selection
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);

  const { resources, tags } = useResources(searchTerm, selectedTags);

  // Toggle or remove a tag
  const handleTagChange = (tag) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
    );
  };

  // Compute the union of tags for the currently filtered resources.
  const activeTagsSet = new Set();
  resources.forEach((resource) => {
    resource.tags.forEach((tag) => activeTagsSet.add(tag));
  });
  const activeTags = Array.from(activeTagsSet);

  return (
    <section>
      <MDBCardTitle className="mb-3 h3 text-center">Resources</MDBCardTitle>

      {/* Search Bar */}
      <div className="mb-3 d-flex justify-content-center">
        <input
          type="text"
          className="form-control w-75"
          placeholder="Search by title or reference..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Tag Filtering (Cloud or Checkboxes) */}
      <ResourceTagCheckboxes
        allTags={tags}
        selectedTags={selectedTags}
        activeTags={activeTags} // Pass the active tags from filtered resources
        onTagChange={handleTagChange}
      />

      {resources.map((resource) => (
        <ResourceCard
          key={resource.url}
          resource={resource}
          onTagClick={handleTagChange}
        />
      ))}
    </section>
  );
}
